import React from "react"
import { ChangeEvent } from "react"

const Input: React.FC<{
  label: string | number
  value: string | number
  id?: string
  name?: string
  type: string
  error?: string
  required: boolean
  placeholder?: string
  blurChanged?: (e: ChangeEvent<HTMLInputElement>) => void
  changed: (e: ChangeEvent<HTMLInputElement>) => void
}> = props => (
  <div className="my-2">
    <label
      className={`leading-7 block text-sm font-bold ${
        props.error ? "text-red-600" : "text-gray-700"
      } `}
      htmlFor={props.id}
    >
      {props.label} {props.required && <span className="text-red-600">*</span>}
    </label>
    <input
      className={`${props.error ? "input-error" : "input"}`}
      type={props.type}
      id={props.id}
      name={props.name}
      required={props.required}
      value={props.value}
      placeholder={props.placeholder}
      onChange={props.changed}
      onBlur={props.blurChanged}
    />
    {props.error && <p className="text-red-600 text-sm mt-2">{props.error}</p>}
  </div>
)

export default Input
