import React from "react"
import { ReactNode } from "react"

const Button = ({
  children,
  clicked,
  addClass,
}: {
  children: ReactNode
  clicked: () => void
  addClass?: string
}) => (
  <button className={`btn-emerald bg-emerald-800 text-white active:bg-emerald-800 rounded-full ${addClass}`} onClick={clicked}>
    {children}
  </button>
)

export default Button
