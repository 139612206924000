import React, { ChangeEvent } from "react"

const Checkbox: React.FC<{
  checkboxText: string
  name: string
  changed: (e: ChangeEvent<HTMLInputElement>) => void
}> = props => (
  <label className="inline-flex my-2">
    <input
      type="checkbox"
      name={props.name}
      onChange={props.changed}
      className="form-checkbox h-5 w-5 rounded-md text-green-600 border-2 focus:border-green-600 outline-none"
    />
    <span className="ml-2">{props.checkboxText}</span>
  </label>
)

export default Checkbox
